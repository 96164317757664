import React, { useEffect, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import './App.css';
import * as ww from '@wecom/jssdk';
import { responsiveFontSizes } from '@mui/material';

function Home() {

  const BASE_URL = "http://sam-wecom.tableau-shanghai-test.com/api/wecom";

  useEffect(() => {

    

      //window.location.href = "https://sftest-with-sales--devwye.sandbox.my.sfcrmproducts.cn//servlet/networks/session/create?url=%2Fs%2Fdetail%2Fa07C5000000viuZIAQ&site=0DMC500000002zV&inst=C5";

  

    // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    // 该代码仅用于快速接入，请勿在生产环境对外暴露JSAPI_TICKET
    // Ticket有效期为2个小时，过期后请手动更换
    // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

    async function execute(corpId, userId) {
      const agentId = "1000014";
      // const corpId = "wpkao6RwAAh73XZB3yWGtT1x4jKBeueA";
      alert(userId);

      const response = await fetch(`${BASE_URL}/token/refresh/${corpId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        mode: 'cors', // Add this option
      });

      if (!response.ok) {
        throw new Error(response.status);
      }

      const jsTicketData = await response.json();

      const JSAPI_TICKET = jsTicketData.jsApiTicket;
      const JS_APP_API_TICKET = jsTicketData.jsAppApiTicket;

      const handleSuccess = (resp) => {
        fetch(`${BASE_URL}/profile/detail/${userId}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
          mode: 'cors', // Add this option
        }).then(response => {
          //alert("success")
          if (!response.ok) {
            throw new Error(response.status);
          }
          return response.text();
        }).then(pageURL => {
          alert(pageURL)
          window.location.href = pageURL;
        })
      };

      ww.register({
        corpId: corpId,
        agentId: agentId,
        jsApiList: ['selectExternalContact', 'getCurExternalContact'],
        getConfigSignature() {
          return ww.getSignature(JSAPI_TICKET)
        },
        getAgentConfigSignature() {
          return ww.getSignature(JS_APP_API_TICKET)
        },
        onConfigSuccess(res) {
          //alert(JSON.stringify(res));
        },
        onAgentConfigSuccess(res) {
          //alert(JSON.stringify(res));
        },
        onConfigFail(res) {
          alert(JSON.stringify(res));
        },
        onAgentConfigFail(res) {
          alert(JSON.stringify(res));
        }
      })

      // 调用 register 后可以立刻调用其他 JS 接口
      ww.getCurExternalContact({
        success(res) {
          handleSuccess(res);
        },
        complete(res) {
          //alert(JSON.stringify(res))
        },
        fail(res) {
          alert(JSON.stringify(res))
        },
      })
    }

    //execute();
  
    const appId = "ww12fba6b01fde5ca3";
    const redirectURI = "http://sam-wecom.tableau-shanghai-test.com/api/wecom/cxgmeta/oauth";
    const authURL = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appId}&redirect_uri=${redirectURI}&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect`;

    const cookieURL = "http://sam-wecom.tableau-shanghai-test.com/api/wecom/cxgmeta/verify"

    async function preExecute() {
      const response = await fetch(cookieURL, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        mode: 'cors', // Add this option
      });

      if (!response.ok) {
        alert("response error.")
        throw new Error(response.status);
      }

      const userInfo = await response.json();
      //alert(JSON.stringify(userInfo));
 
      if (userInfo.corpid !== undefined) {
        execute(userInfo.corpid, userInfo.userid);
      } else {
        window.location.href = authURL;
      }
    }

    preExecute();
    
  }, [])

  return (
    <div className="App">
      <CircularProgress />
    </div>
  );
}

export default Home;
